import { notifyBugsnag } from './bugsnagClient';
import { loggers } from './loggers';
import { isErrorOrString } from './typeGuards';
import { SSR } from './utilities';
// eslint-disable-next-line yi/avoid-relative-parent-imports-v2
import { NonNullable } from '../types';

const logger = loggers.new_relic;

const getNr = (args: unknown[]) => {
  try {
    if (SSR) return null;
    const newRelic = window.newrelic;
    if (!newRelic) throw new Error('NR client not found on window');
    return newRelic;
  } catch (e) {
    notifyBugsnag(`NEW_RELIC - data not reported: ${e}`, { args });
  }
  return null;
};

type GetArgs<T extends keyof NonNullable<ReturnType<typeof getNr>>> = Parameters<
  NonNullable<ReturnType<typeof getNr>>[T]
>;

const ACTION_NAMES = {
  ADYEN_PAYMENT: 'AdyenPayment',
  ADYEN_PAYMENT_IFRAME_ERR: 'AdyenPaymentIframeError',
  VALIDATE_JWT_MATCHES_USER: 'ValidateJwtMatchesUser',
  FETCH_PLANS: 'FetchPlans',
  FETCH_BROOKLYN: 'FetchBrooklyn',
  FINGERPRINT_JS_BLOCKED: 'FingerprintJsBlocked',
  BROOKLYN_REQUEST: 'BrooklynRequest',
  BROOKLYN_RESPONSE: 'BrooklynResponse',
  CHECKOUT_ISSUES: 'CheckoutIssues',
  CHECKOUT_TIMINGS: 'CheckoutTimings',
  CHECKOUT_ALREADY_SUBSCRIBED: 'CheckoutAlreadySubscribed',
  404: '404',
  ENTITLEMENTS_ACCESS_VALIDATION: 'EntitlementsAccessValidation',
  ENTITLEMENTS_SUB_VALIDATION: 'EntitlementsSubValidation',
  ENTITLEMENTS_VALIDATION_SKIPPED: 'EntitlementsValidationSkipped',
  GRAPHQL_OPERATION_DURATIONS: 'GraphqlOperationDurations',
  CHECKOUT_LOAD_FORM: 'CheckoutLoadForm',
  BRIGHTCOVE_MEDIA_CONSUMED: 'BrightcoveMediaConsumed',
  BRIGHTCOVE_MEDIA_SRC_LOADED: 'BrightcoveMediaSrcLoaded',
  BRIGHTCOVE_MEDIA_QUALITY_CHANGED: 'BrightcoveMediaQualityChanged',
  WINBACK_FLOW: 'WinbackFlow',
  ZENDESK_PREVENTED_SCROLL_ISSUE: 'ZendeskPreventedScrollIssue',
  CANCEL_MEMBERSHIP_REASON: 'CancelMembershipReason',
  CANCELATION_DEFLECTION_ACCEPTED_OFFER: 'CancelationDeflectionAcceptedOffer',
  FINDER_PAGE_NO_RESULTS: 'FinderPageNoResults',
  ADD_TO_CALENDAR_TARGET: 'AddToCalendarTarget',
  WARNING: 'Warning',
  USER_CLICK: 'UserClick',
};

/***
 * PageAction events are sent every 30 seconds, with a maximum of 120 events per 30-second harvest cycle, per browser.
 *
 * "If page unload occurs sometime before the end of the 30-second harvest cycle, it attempts to report all of the buffered events"
 *     - https://discuss.newrelic.com/t/pageaction-event-not-sent-when-browser-is-closed/51131
 */
const addAction = (...args: GetArgs<'addPageAction'>) => {
  logger.log('addAction', ...args);
  try {
    getNr(args)?.addPageAction(...args);
  } catch (e) {
    notifyBugsnag(`New Relic method error: ${e}`, { args });
  }
};

/***
 * The PageView event is a default browser-reported event. You can add custom attributes to the PageView event.
 * Any custom attributes you add to the PageView event are also automatically added to the PageAction event.
 *
 * If you are using SPA monitoring with a compatible agent version, attributes set with this call will also be
 * included in newrelic.interaction events. However, attributes set using the SPA API will take precedence over these attributes.
 */
const setAttribute = (...args: GetArgs<'setCustomAttribute'>) => {
  logger.log('setAttribute', ...args);
  try {
    getNr(args)?.setCustomAttribute(...args);
  } catch (e) {
    notifyBugsnag(`New Relic method error: ${e}`, { args });
  }
};

const noticeError = (...args: GetArgs<'noticeError'>) => {
  logger.log('noticeError', ...args);
  try {
    const [error, customAttributes] = args;
    /***
     * Browser limitations (Apple Safari and Microsoft Internet Explorer only)
     * If an error is not thrown, it will not have a stack trace. If you want the newrelic.noticeError API to report
     * a stack trace with all browser types, you must manually throw and then catch the error before you pass it to the API.
     *   - https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/noticeerror/#browser-limits
     */
    try {
      throw error;
    } catch (e) {
      if (isErrorOrString(e)) {
        getNr(args)?.noticeError(e, customAttributes);
      }
    }
  } catch (e) {
    notifyBugsnag(`New Relic method error: ${e}`, { args });
  }
};

const noticeWarning = (
  type: string,
  metadata: { [k: string]: undefined | string | number | boolean },
) => {
  addAction(ACTION_NAMES.WARNING, { type, ...metadata });
};

const noticedErrors: { [k: string]: undefined | boolean } = {};
const noticeErrorOnce = (...args: GetArgs<'noticeError'>) => {
  const [error] = args;
  const key = typeof error === 'string' ? error : `${error}`;
  if (noticedErrors[key]) return;
  noticedErrors[key] = true;
  noticeError(...args);
};

export const maybeReportInvalidPostalCode = ({
  errors,
}: {
  errors?: { errorMessage: string }[];
}) => {
  try {
    const invalidPostalCodeErrors = [
      'Error: please enter a valid postal code',
      'Error: escribe un código postal válido.',
    ];
    const isInvalidPostalCode = errors?.find(({ errorMessage }) =>
      invalidPostalCodeErrors.includes(errorMessage),
    );

    if (isInvalidPostalCode) {
      addAction('invalid_postal_code');
      noticeError('invalid_postal_code');
      notifyBugsnag('invalid_postal_code'); // debug whether not firing or not showing
    }
  } catch (e) {
    console.error(e);
  }
};

export const nr = {
  ...ACTION_NAMES,
  noticeError,
  noticeErrorOnce,
  noticeWarning,
  setAttribute,
  addAction,
};
