import * as yup from 'yup';
import { Asserts } from 'yup';
import { TypedSchema } from 'yup/lib/util/types';
import { Values } from '@yi/core';
import { USER_JWT_KEY } from '@yi/core/src/brooklyn-jwt/useBrooklynUserJwt';

import { LAST_CLOSED_SALE_BANNER_STORAGE_KEY } from '~/src/common/courses/sale/useShowSaleBanner/constants';
import { AFTER_PASSWORD_RESET_SIGN_UP_REDIRECT_URL_STORAGE_KEY } from '~/src/common/sign-in/storage-keys';
import { CONVERTED_CONTENT_FROM_STORAGE_KEY } from '~/src/common/sign-up/converted-content-from/constants';
import { LAST_CLOSED_DEFAULT_BANNER_STORAGE_KEY } from '~/src/common/site-wide-banner/constants';
import { PHRASE_BUILDER_STORAGE_KEY } from '~/src/pages/admin/phrase-builder/_constants';
import { ARTICLES_VIEWED_STORAGE_KEY } from '~/src/templates/content/Article/ArticlePageContent/constants';

import { HAS_SEEN_ADD_TO_CALENDAR_PROMO_STORAGE_KEY } from '../../add-to-calendar/storage-keys';
import { featureFlagStorageKeys } from '../../featureFlagStorageKeys';
import {
  HAS_SEEN_SERIES_PROMO_STORAGE_KEY,
  LAST_SEEN_SERIES_PROMO_STORAGE_KEY,
} from '../../lean-back/storage-keys';
import { loggerFlagStorageKeys } from '../../loggers/loggerFlagStorageKeys';

type FeatureFlagSchema = { [K in Values<typeof featureFlagStorageKeys>]: string };
type LoggerFlagSchema = { [K in Values<typeof loggerFlagStorageKeys>]: string };

export const fieldValidators = {
  token: yup.string(),
  email: yup.string(),
  cookiesAccepted: yup.string(),
  [USER_JWT_KEY]: yup.string(),
  [HAS_SEEN_SERIES_PROMO_STORAGE_KEY]: yup.string(),
  [LAST_SEEN_SERIES_PROMO_STORAGE_KEY]: yup.string(),
  [LAST_CLOSED_SALE_BANNER_STORAGE_KEY]: yup.string(),
  [LAST_CLOSED_DEFAULT_BANNER_STORAGE_KEY]: yup.string(),
  [HAS_SEEN_ADD_TO_CALENDAR_PROMO_STORAGE_KEY]: yup.string(),
  [AFTER_PASSWORD_RESET_SIGN_UP_REDIRECT_URL_STORAGE_KEY]: yup.string(),
  [CONVERTED_CONTENT_FROM_STORAGE_KEY]: yup.string(),
  [PHRASE_BUILDER_STORAGE_KEY]: yup.string(),
  [ARTICLES_VIEWED_STORAGE_KEY]: yup.array().of(yup.string()),
  // @TODO run through consent management solution?
  // UTM data captured from query string, used to track marketing attribution
  utmData: yup.object({
    // ISO date string of when the values were stored
    capturedOn: yup.string().defined(),
    // Query string of UTM parameters, e.g. `utm_source=youtube&utm_campaign=...`
    utmQueryString: yup.string().defined(),
  }),

  // Add logger flags
  ...Object.values(loggerFlagStorageKeys).reduce((acc, key) => {
    acc[key] = yup.string();
    return acc;
  }, {} as { [K in keyof LoggerFlagSchema]: yup.StringSchema }),

  // Add feature flags
  ...Object.values(featureFlagStorageKeys).reduce((acc, key) => {
    acc[key] = yup.string();
    return acc;
  }, {} as { [K in keyof FeatureFlagSchema]: yup.StringSchema }),
};

type MapYupSchemaToType<O extends { [k: string]: TypedSchema | undefined }> = {
  [K in keyof O]: O[K] extends yup.BaseSchema ? Asserts<O[K]> : O[K];
};
export type StorageSchema = MapYupSchemaToType<typeof fieldValidators>;
