/* eslint-disable react/jsx-filename-extension, @typescript-eslint/prefer-optional-chain */
/* eslint-disable yi/avoid-relative-parent-imports-v2 */
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CacheProvider } from '@emotion/react';

import { StorageProvider } from '~/common';
import {
  PlaylistPlayer,
  PlaylistProvider,
  SearchInsightsProvider,
  BrooklynUserJwtProvider,
} from '~/components';

import { ApolloProvider } from './src/ApolloProvider';
import { notifyBugsnag, bugsnagClient } from './src/common/bugsnagClient.ts';
import { oneTrust } from './src/common/oneTrust/oneTrust';
import { NewContentAlert } from './src/components/LeanBack';
import { LocaleProvider } from './src/components/LocaleProvider';
import { GatsbyBrowserRootElementSideEffects } from './src/components/GatsbyBrowserRootElementSideEffects';
import { GatsbyBrowserPageElementSideEffects } from './src/components/GatsbyBrowserPageElementSideEffects';
import { PageContextProvider } from './src/components/PageContextProvider';
import { UncaughtErrorFallback } from './src/components/UncaughtErrorFallback.tsx';
import { createEmotionCache, GlobalStyles } from './src/gatsby';
import { loadZendeskScript } from './src/gatsby/loadZendeskScript';
import { materialUiTheme } from './src/gatsby/materialUiTheme';
import { ReactQueryProvider } from './src/ReactQueryProvider';
import { CurrentUserSubscriptonCacheUpdateProvider } from './src/common/subscription/CurrentUserSubscriptonCacheUpdateProvider';

const BugsnagErrorBoundary = bugsnagClient.getPlugin('react').createErrorBoundary(React);

let stripeResolver;

const stripePromise = new Promise(resolve => (stripeResolver = resolve));

/*
 * To prevent fraud Stipe recommends to load Stripe on all pages.
 * see details here https://stripe.com/docs/disputes/prevention/advanced-fraud-detection#when-signals-are-collected
 * But at least we can delay loading until the first render
 * NOTE: Probably it makes sense to try to use requestIdleCallback https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback to run loading, when Safary supports it.
 * Currently Safari is the only browser that doesn't support requestIdleCallback, but it's already in TP (Feb 2023)
 */
export const onInitialClientRender = () => {
  stripeResolver(loadStripe(process.env.GATSBY_STRIPE_API_KEY));
  loadZendeskScript();
};

export const onClientEntry = () => {
  // Hotfix for github.com/gatsbyjs/gatsby/issues/18866 on Chrome browsers
  // If unhandled Promise rejection occurs due to ChunkLoadError, set Gatsby's
  // `swUpdated` flag to true, causing next navigation attempt to a full page
  // reload instead of a router-level navigate attempt, which may fail due to
  // missing chunk from outdated build
  try {
    window.addEventListener('unhandledrejection', promiseRejectionEvent => {
      if (
        promiseRejectionEvent &&
        promiseRejectionEvent.reason &&
        promiseRejectionEvent.reason.name === 'ChunkLoadError'
      ) {
        notifyBugsnag('ChunkLoadError hotfix fired! Next navigation attempt will reload the page');
        window.___swUpdated = true;
      }
    });
  } catch (e) {
    notifyBugsnag(e);
  }
};

/** ApolloProviderWithCachePersist requires a StaticQuery to determine
 * whether cache should break. StaticQuery supported in wrapPageElement
 *but not wrapRootElement github.com/gatsbyjs/gatsby/issues/7747
 */
export const wrapPageElement = ({ element, props: { pageContext, location } }) => {
  const { locale = 'en' } = pageContext;
  return (
    <PageContextProvider pageContext={pageContext}>
      <LocaleProvider locale={locale}>
        {element}
        <PlaylistPlayer location={location} />
        <NewContentAlert />
        <GatsbyBrowserPageElementSideEffects />
      </LocaleProvider>
    </PageContextProvider>
  );
};

const onLoginSideEffects = [oneTrust.onLogin];
const onLogoutSideEffects = [oneTrust.onLogout];

const cache = createEmotionCache();
export const wrapRootElement = ({ element }) => (
  <CacheProvider value={cache}>
    <ThemeProvider theme={materialUiTheme}>
      <CssBaseline />
      <GlobalStyles />
      <BugsnagErrorBoundary FallbackComponent={UncaughtErrorFallback}>
        <Elements stripe={stripePromise}>
          <StorageProvider
            onLoginSideEffects={onLoginSideEffects}
            onLogoutSideEffects={onLogoutSideEffects}
          >
            <ApolloProvider>
              <BrooklynUserJwtProvider>
                <ReactQueryProvider>
                  <GatsbyBrowserRootElementSideEffects />
                  <PlaylistProvider>
                    <SearchInsightsProvider>
                      <CurrentUserSubscriptonCacheUpdateProvider>
                        {element}
                      </CurrentUserSubscriptonCacheUpdateProvider>
                    </SearchInsightsProvider>
                  </PlaylistProvider>
                </ReactQueryProvider>
              </BrooklynUserJwtProvider>
            </ApolloProvider>
          </StorageProvider>
        </Elements>
      </BugsnagErrorBoundary>
    </ThemeProvider>
  </CacheProvider>
);

export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  try {
    if (routerProps.location.state && routerProps.location.state.forceUpdateScroll) return true;

    // Prevent delayed scroll position change from interrupting user on AlgoliaSearch pages
    // after adding/removing search refinements
    const samePath = routerProps.location.pathname === prevRouterProps.location.pathname;
    const sameQueryString = routerProps.location.search === prevRouterProps.location.search;
    if (samePath && !sameQueryString) return false;
  } catch (e) {
    notifyBugsnag(e);
  }
  return true;
};

// @TODO Fully remove need for old styles
// Load global styles
require('./src/styles/global/index.scss');
